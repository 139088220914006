<template>
  <div id="app">
    <el-backtop />
    <div v-show="!isdocument">
      <router-view />
    </div>
    <documentPage v-if="isdocument" />
    <div v-if="showBut">
      <OldFloatBut />
      <FloatBut />
    </div>
  </div>
</template>

<script>
import OldFloatBut from '@/components/FloatBut.vue';
import FloatBut from '@/components/newFloatBtn';
import { getStorage } from '@/util/util';
import { getToken } from '@/util/auth';
import documentPage from './views/documentPage.vue';

export default {
  name: 'App',
  components: {
    FloatBut,
    OldFloatBut,
    documentPage,
  },
  data() {
    return {
      showBut: true,
      isdocument: false,
    };
  },
  watch: {
    $route(nv) {
      this.isdocument = nv.name === 'documentPage';
    },
  },
  created() {
    window.location.href.indexOf('iphoneFrom') !== -1 ? (this.showBut = false) : '';
    window.location.href.indexOf('downloadApp') !== -1 ? (this.showBut = false) : '';
  },
  mounted() {
    // 在页面加载时读取localStorage里的状态信息
    if (getToken()) {
      // 先判断 cookie中的token 是否存在 过期的就不需要取缓存了
      if (getStorage('local', 'jepaasStore')) {
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(getStorage('local', 'jepaasStore')),
          ),
        );
      }
    }
  },
};
</script>

<style lang='less'>
/* 黑白网页 */
/*html {filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1); -webkit-filter: grayscale(100%); }*/
#app{
  width: 100%;
  height: 100%;
}
.el-dialog {
  border-radius: 10px!important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
}
.el-dialog .el-dialog__body{
  overflow: auto;
  color: #666666;
  border-radius:10px;
}
html,
body {
  margin: 0;
  padding: 0;
  color: #3f3f3f;
}
.el-message p {
  font-size: 18px;
}
.anchorBL,
.BMap_cpyCtrl {
  display: none !important;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  .el-backtop {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 4px;
    width: 52px;
    height: 52px;
    right: 2% !important;
    bottom: 43px !important;
    font-size: 24px;
    color: #ff3041;
    &:hover {
      background: #ff3041;
      > i {
        color: #ffffff;
      }
    }
  }
  img {
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.van-image__img {
  width: 100%;
}
</style>
