<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-03-26 13:54:59
 * @LastEditTime: 2020-04-09 13:37:40
 -->
<template>
  <div class="page_introduced_box">
    <div class="box_top">
      <Header class="page_hea" />
    </div>
    <div class="iframe_wrp">
      <iframe
        src="http://docs.jepaas.com/#/平台介绍/介绍"
        frameborder="0"
        class="iframe"
      />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import img_top from '@/assets/imgs/about/img_top.png';

export default {
  name: 'IntroducedPage',
  components: { Header },
  data() {
    return {
      img_top,
    };
  },
};
</script>

<style lang="less" scoped>

.page_introduced_box {
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .iframe_wrp {
    position: absolute;
    top: 90px;
    right: 0;
    bottom: 0;
    left: 0;
    .iframe {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
    }
    &:after {
      content: '文档加载中...';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: #dddddd;
      font-size: 30px;
      font-weight: bold;
    }
  }
}
</style>
