/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-13 13:23:49
 * @LastEditTime: 2020-12-09 14:42:19
 */
import Vue from 'vue';
import router from '@/router/router';
import store from '@/store/index';
import collectData from '@/util/buryPoint';
// 引入公共的商城加载样式组件
import jepaasLoading from '@/components/loading/main';

import animated from 'animate.css';

// import load from './assets/imgs/loading.png'
import ElemetUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
// 导入由axios封装的ajax模块
import ajax from '@/assets/js/ajax';
// 导入rem计算模块
import '@/assets/js/rem';
// 导入 VueAwesomeSwiper 插件
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
// 引入关键词高亮的方法
import TextHighlight from 'vue-text-highlight';
// 引入vant
import { Lazyload, Image, Loading } from 'vant';
// PDF转

// 地图
import BaiduMap from 'vue-baidu-map';
import { getToken } from '@/util/auth';
import htmlToPdf from '../../util/htmlToPdf';
import App from './App.vue'; // npm install animate.css --save安装，在引入
// 引用富文本编辑器
// 引入富文本css
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
// 富文本编辑器添加实例
Vue.use(animated);

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'RkonaInfprG0ks0K7mq4OsBezsjgWbuR',
});
// options 为可选参数，无则不传
Vue.use(Image);
Vue.use(Loading);
Vue.use(Lazyload, {});

Vue.use(VueAwesomeSwiper);
Vue.use(ajax);
Vue.use(ElemetUI);
Vue.use(htmlToPdf);
Vue.use(jepaasLoading); // 全局使用loading组件
Vue.component('text-highlight', TextHighlight);
Vue.config.productionTip = false;
// 路由拦截
router.beforeEach((to, from, next) => {
  if (to.meta.content) {
    document.title = to.meta.content.title;
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    document.querySelector('meta[name="author"]').setAttribute('content', to.meta.content.author)
    document.querySelector('meta[name="copyright"]').setAttribute('content', to.meta.content.copyright
    )
  } else {
    document.title = 'JEPaaS_低代码平台_低代码开发_企业SCRM-凯特伟业'
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'JEPaaS,低代码平台,低代码开发,企业SCRM,低代码开发平台排名,apaas,制造行业代码,业务流程管理,saas开发框架')
    document.querySelector('meta[name="description"]').setAttribute('content', '北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。目前，公司主要产品包括JEPaaS开发平台、蒜瓣SCRM等，低代码开发平台排名，apaas，制造行业代码，业务流程管理，saas开发框架，activiti工作流，单点登录，微服务架构，low code，可视化流程设计，移动应用，免费低代码平台，水业工程管理，安全管理系统，CRM管理系统，移动办公平台，并提供定制开发和SaaS服务。')
    document.querySelector('meta[name="author"]').setAttribute('content', '凯特伟业')
    document.querySelector('meta[name="copyright"]').setAttribute('content', '北京凯特伟业科技有限公司')
  }

  if (to.meta.auth) {
    // 未登录
    if (getToken()) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});
router.afterEach((to) => {
  collectData(to, 'home');
});
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app');
